.pdpWrapper {
  display: flex;
  flex-direction: column;
  gap: 0 var(--spacing-8);

  @media (--tablet) {
    flex-direction: row;
    margin-top: var(--spacing-4);

    & .mainCol {
      min-width: 60%;
    }
  }
}
.mainPdpWrapper {
  [data-service-consumer='native'] & {
    margin-bottom: var(--spacing-16);
  }
}
.container {
  display: flex;
  flex-direction: column;
  gap: 0 var(--spacing-10);
  margin-bottom: var(--spacing-10);
  justify-content: space-between;
  width: 100%;

  @media (--tablet-l) {
    flex-direction: row;
    padding-top: var(--spacing-8);
  }

  @media (--desktop-l) {
    padding-top: var(--spacing-16);
  }

  & [class^='UspsItems_uspsPadding'] {
    order: 2;
    background-color: white;
    padding-top: var(--spacing-4);

    @media (--tablet-l) {
      order: unset;
      position: relative;
      z-index: 1;
      margin-bottom: -20px;
    }

    &[data-scrolled='true'] {
      @media (--tablet-l) {
        top: 70px;
        margin-bottom: 50px;
      }
      @media (--desktop-l) {
        top: 60px;
        margin-bottom: 40px;
      }
    }
  }
}

.leftCol {
  min-width: 0;
  display: contents;
  flex-direction: column;
  flex: 0 1 868px;

  @media (--tablet-l) {
    display: flex;
  }
}

.rightCol {
  min-width: 0;
  display: contents;
  flex-direction: column;
  flex: 0 0 300px;

  @media (--tablet-l) {
    display: flex;
  }
}

.galleryContainer {
  order: 1;
  position: relative;
  margin-left: calc(var(--spacing-5) * -1);
  width: 100vw;

  margin-bottom: 16px;

  @media (--tablet) {
    margin-left: calc(var(--spacing-6) * -1);
  }

  @media (--tablet-l) {
    width: unset;
    margin-left: unset;
  }
}

.vehicleInfoContainer {
  order: 2;
  flex-direction: column;
  display: flex;

  @media (--tablet-l) {
    flex-wrap: wrap;
    order: unset;
    height: 100%;
    background: var(--color-championship-white);
    z-index: 8;
    padding-left: var(--spacing-3);
  }
}

.vehicleDetailsContainer {
  order: 3;
}

.sideCol {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.image {
  width: 100%;
  aspect-ratio: 4/3;
  object-fit: contain;
  object-position: center;
}

.buyOnlineCTA {
  width: 100%;
  margin-bottom: 10px;
}

.pdpExternalAdsPadding {
  padding-top: var(--spacing-3);
}

.pdpExternalSidebarAds {
  display: none;
  padding-top: var(--spacing-3);

  @media (--tablet-l) {
    display: block;
  }
}
